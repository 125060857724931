<template>
  <div>
    <div class="banner">
      <img src="@/assets/img/companyList/banner.png" alt="" />
    </div>
    <div :class="['searchArea', searchFixed ? 'is_fixed' : '']">
      <el-input
        v-model="searchKey"
        style="width: 100%; margin: 0 auto; text-align: center"
        placeholder="搜索公司"
        @input="searchLodash"
        prefix-icon="el-icon-search"
      ></el-input>
    </div>
    <div class="tableArea">
      <el-table
        :data="tableData"
        style="
          margin: 0 auto;
          border-radius: 4px;
          height: 1000px;
          overflow: auto;
        "
        v-infinite-scroll="load"
      >
        <el-table-column width="100">
          <template slot="header">
            <div>公司名称</div>
          </template>
          <template slot-scope="scope">
            <div class="companyContainer">
              <div>
                <img class="companyLogo" :src="scope.row.logo" alt="" />
                <br />
                <div class="companyName" :title="scope.row.name">
                  {{ scope.row.name }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <div>最新信息</div>
          </template>
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.latestInformationList &&
                scope.row.careerFair.latestInformationList.length > 0
              "
            >
              <div class="contentContainer" v-if="scope.row.careerFair">
                <div
                  v-for="(comment, index) in scope.row.careerFair
                    .latestInformationList"
                  :key="index"
                  class="comment"
                >
                  {{ comment.content }}
                </div>
                <div style="text-align: center">
                  <el-button
                    type="primary"
                    class="submitBtn"
                    size="small"
                    @click="openPc"
                    >提供信息</el-button
                  >
                </div>
              </div>
              <div
                slot="reference"
                class="jobFeelings"
                style="cursor: pointer"
                @click="jumpTrends(scope.row, '1')"
              >
                {{ scope.row.careerFair.latestInformationList[0].content }}
              </div>
            </el-popover>
            <div v-else class="applyLink" @click="openPc">提供信息</div>
          </template>
        </el-table-column>
        <el-table-column width="115">
          <template slot="header">
            <div style="position: relative">
              <div class="orange">New Grad <br />Salary &amp; Link</div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="orange"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['1'] &&
                scope.row.careerFair.details['1'].annualSalary
              "
            >
              {{ !scope.row.isDomestic ? "$" : "￥"
              }}{{ scope.row.careerFair.details["1"].annualSalary }} /y
            </div>
            <div
              class="orange"
              v-else
              style="
                cursor: pointer;
                text-decoration: underline;
                display: inline-block;
              "
              @click="openPc"
            >
              提供信息
            </div>
            <div class="newGrad" style="cursor: pointer">
              <div
                class="applyLink"
                v-if="
                  scope.row.careerFair &&
                  scope.row.careerFair.details['1'] &&
                  scope.row.careerFair.details['1'].link
                "
                @click="jumpLink(scope.row.careerFair.details['1'].link)"
              >
                Apply&nbsp;→
              </div>
              <div
                class="notOpenLink"
                v-else
                @click="jumpLink(scope.row.careerFair.link)"
              >
                Unknown
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="115">
          <template slot="header">
            <div>
              <div class="blue">
                Internship
                <br />
                Salary &amp; Link
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="blue"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['0'] &&
                scope.row.careerFair.details['0'].monthlySalary
              "
            >
              {{ !scope.row.isDomestic ? "$" : "￥"
              }}{{ scope.row.careerFair.details["0"].monthlySalary }} /m
            </div>
            <div
              class="blue"
              v-else
              style="
                cursor: pointer;
                text-decoration: underline;
                display: inline-block;
              "
              @click="openPc"
            >
              提供信息
            </div>
            <div class="internship">
              <div
                class="applyLink"
                v-if="
                  scope.row.careerFair &&
                  scope.row.careerFair.details['0'] &&
                  scope.row.careerFair.details['0'].link
                "
                @click="jumpLink(scope.row.careerFair.details['0'].link)"
              >
                Apply&nbsp;→
              </div>
              <div
                class="notOpenLink"
                v-else
                @click="jumpLink(scope.row.careerFair.link)"
              >
                Unknown
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getCompanyResource } from "@/service/company";
import _ from "lodash";
import { mapState } from "vuex";
import { Toast } from "vant";

export default {
  name: "Companies",
  data() {
    return {
      searchKey: "",
      currentPage: 1,
      total: 0,
      tableData: [],
      currentId: "",
      suggestionText: "",
      pageSize: 20,
      writeType: "",
      order: "",
      orderType: null,
      searchFixed: false,
    };
  },
  methods: {
    scrollTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.searchFixed = scrollTop > 475;
    },
    getResources() {
      //   if (!this.userInfo || !this.userInfo.id) {
      //     this.$router.push("/wechatLogin");
      //   }
      getCompanyResource({
        current: 1,
        name: this.searchKey,
        size: 20,
      }).then((res) => {
        if (res.success) {
          window.scrollTo(0, 0);
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    jumpLink(url) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/wechatLogin");
      }
      window.open(url);
    },
    searchLodash: _.debounce(function () {
      this.getResources(1);
    }, 1000),
    openPc() {
      Toast("请电脑端访问cspiration.com填写相关信息");
    },
    load() {
      if (this.tableData.length >= this.total) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      getCompanyResource({
        current: this.currentPage,
        name: this.searchKey,
        size: 20,
      }).then((res) => {
        if (res.success) {
          this.tableData = this.tableData.concat(res.result.records);
          this.total = res.result.total;
        }
      });
    },
  },
  mounted() {
    this.getResources();
    document.addEventListener("scroll", this.scrollTop);
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    document.removeEventListener("scroll", this.scrollTop);
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;

  img {
    width: 100%;
    vertical-align: top;
  }
}
.searchArea {
  text-align: center;
  padding: 20px;
}
.orange {
  color: rgba(250, 100, 0, 1);
  font-size: 14px;
}
.blue {
  color: rgba(10, 122, 255, 1);
  font-size: 14px;
}
.companyContainer {
  display: flex;
  padding: 20px 0;
  .companyLogo {
    width: 30px;
    height: 30px;
  }
  .companyName {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .companyDes {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.8);
    line-height: 18px;
  }
}
.applyLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(10, 122, 255, 0.3);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 18px;
  cursor: pointer;
}
.notOpenLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(118, 118, 128, 0.24);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 18px;
  cursor: pointer;
}
::v-deep .el-table th {
  background: #f4f4f4;
}
.loginTip {
  text-align: center;
  padding: 30px;
}
.provideContainer .el-button {
  margin-bottom: 24px;
  &:hover {
    background: #0a7aff;
    color: #fff;
  }
}
.contentContainer {
  padding: 24px;
  .comment {
    width: 100%;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    text-decoration: underline;
    margin-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.el-radio.is-bordered {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    padding-left: 0;
  }
}
::v-deep .el-radio--small.is-bordered {
  padding: 8px 10px;
  border-radius: 16px;
}
::v-deep .el-radio {
  margin-right: 0px;
}
.submitBtn {
  width: 160px;
  border-radius: 16px;
  margin: 0 auto;
}
::v-deep .el-dialog {
  border-radius: 6px;
  width: 30%;
}
.jobFeelings {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
::v-deep .writeSug .el-dialog__body {
  padding-bottom: 0px;
}
.active {
  color: #0a7aff;
}
.provideBtn {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
</style>