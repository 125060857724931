var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{class:['searchArea', _vm.searchFixed ? 'is_fixed' : '']},[_c('el-input',{staticStyle:{"width":"100%","margin":"0 auto","text-align":"center"},attrs:{"placeholder":"搜索公司","prefix-icon":"el-icon-search"},on:{"input":_vm.searchLodash},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1),_c('div',{staticClass:"tableArea"},[_c('el-table',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.load),expression:"load"}],staticStyle:{"margin":"0 auto","border-radius":"4px","height":"1000px","overflow":"auto"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"companyContainer"},[_c('div',[_c('img',{staticClass:"companyLogo",attrs:{"src":scope.row.logo,"alt":""}}),_c('br'),_c('div',{staticClass:"companyName",attrs:{"title":scope.row.name}},[_vm._v(" "+_vm._s(scope.row.name)+" ")])])])]}}])},[_c('template',{slot:"header"},[_c('div',[_vm._v("公司名称")])])],2),_c('el-table-column',{attrs:{"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.careerFair &&
              scope.row.careerFair.latestInformationList &&
              scope.row.careerFair.latestInformationList.length > 0
            )?_c('el-popover',{attrs:{"placement":"right","width":"300","trigger":"hover"}},[(scope.row.careerFair)?_c('div',{staticClass:"contentContainer"},[_vm._l((scope.row.careerFair
                  .latestInformationList),function(comment,index){return _c('div',{key:index,staticClass:"comment"},[_vm._v(" "+_vm._s(comment.content)+" ")])}),_c('div',{staticStyle:{"text-align":"center"}},[_c('el-button',{staticClass:"submitBtn",attrs:{"type":"primary","size":"small"},on:{"click":_vm.openPc}},[_vm._v("提供信息")])],1)],2):_vm._e(),_c('div',{staticClass:"jobFeelings",staticStyle:{"cursor":"pointer"},attrs:{"slot":"reference"},on:{"click":function($event){return _vm.jumpTrends(scope.row, '1')}},slot:"reference"},[_vm._v(" "+_vm._s(scope.row.careerFair.latestInformationList[0].content)+" ")])]):_c('div',{staticClass:"applyLink",on:{"click":_vm.openPc}},[_vm._v("提供信息")])]}}])},[_c('template',{slot:"header"},[_c('div',[_vm._v("最新信息")])])],2),_c('el-table-column',{attrs:{"width":"115"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.careerFair &&
              scope.row.careerFair.details['1'] &&
              scope.row.careerFair.details['1'].annualSalary
            )?_c('div',{staticClass:"orange"},[_vm._v(" "+_vm._s(!scope.row.isDomestic ? "$" : "￥")+_vm._s(scope.row.careerFair.details["1"].annualSalary)+" /y ")]):_c('div',{staticClass:"orange",staticStyle:{"cursor":"pointer","text-decoration":"underline","display":"inline-block"},on:{"click":_vm.openPc}},[_vm._v(" 提供信息 ")]),_c('div',{staticClass:"newGrad",staticStyle:{"cursor":"pointer"}},[(
                scope.row.careerFair &&
                scope.row.careerFair.details['1'] &&
                scope.row.careerFair.details['1'].link
              )?_c('div',{staticClass:"applyLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.details['1'].link)}}},[_vm._v(" Apply → ")]):_c('div',{staticClass:"notOpenLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.link)}}},[_vm._v(" Unknown ")])])]}}])},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"orange"},[_vm._v("New Grad "),_c('br'),_vm._v("Salary & Link")])])])],2),_c('el-table-column',{attrs:{"width":"115"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.careerFair &&
              scope.row.careerFair.details['0'] &&
              scope.row.careerFair.details['0'].monthlySalary
            )?_c('div',{staticClass:"blue"},[_vm._v(" "+_vm._s(!scope.row.isDomestic ? "$" : "￥")+_vm._s(scope.row.careerFair.details["0"].monthlySalary)+" /m ")]):_c('div',{staticClass:"blue",staticStyle:{"cursor":"pointer","text-decoration":"underline","display":"inline-block"},on:{"click":_vm.openPc}},[_vm._v(" 提供信息 ")]),_c('div',{staticClass:"internship"},[(
                scope.row.careerFair &&
                scope.row.careerFair.details['0'] &&
                scope.row.careerFair.details['0'].link
              )?_c('div',{staticClass:"applyLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.details['0'].link)}}},[_vm._v(" Apply → ")]):_c('div',{staticClass:"notOpenLink",on:{"click":function($event){return _vm.jumpLink(scope.row.careerFair.link)}}},[_vm._v(" Unknown ")])])]}}])},[_c('template',{slot:"header"},[_c('div',[_c('div',{staticClass:"blue"},[_vm._v(" Internship "),_c('br'),_vm._v(" Salary & Link ")])])])],2)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/img/companyList/banner.png"),"alt":""}})])
}]

export { render, staticRenderFns }